<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('unit.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <v-row>
      <v-col cols="6">
        {{ $t('unit.projectName') }}
      </v-col>
      <v-col cols="6">
        {{ detail.project_name }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('unit.link') }}
      </v-col>
      <v-col cols="6">
        <a :href="`${websiteUrl}${detail.url}`" target="_blank">{{
          `${websiteUrl}${detail.url}`
        }}</a>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('unit.modelName') }}
      </v-col>
      <v-col cols="6">
        {{ detail.unit_type }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('unit.clusterName') }}
      </v-col>
      <v-col cols="6">
        {{ detail.category_name }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('unit.block') }}
      </v-col>
      <v-col cols="6">
        {{ detail.unit_block }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('unit.number') }}
      </v-col>
      <v-col cols="6">
        {{ detail.unit_number }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('general.bedroom') }}
      </v-col>
      <v-col cols="6">
        {{ detail.bedroom }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('general.bathroom') }}
      </v-col>
      <v-col cols="6">
        {{ detail.bathroom }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('general.landSize') }}
      </v-col>
      <v-col cols="6">
        {{ detail.land_size }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('general.buildingSize') }}
      </v-col>
      <v-col cols="6">
        {{ detail.building_size }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('unit.price') }}
      </v-col>
      <v-col cols="6">
        {{ detail.price }}
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
      websiteUrl: (state) => state.global.websiteUrl,
      baseUrl: (state) => state.global.baseUrl,
    }),
  },
  methods: {},
};
</script>
